//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  sectionsStore,
  teamsStore,
  uiStore,
  uiSettingsStore,
} from '@/store'

export default {
  name: 'TaskDeskColumnHeaderMenu',

  props: {
    uid: {
      type: String,
      required: true,
    },
    columnKey: {
      type: String,
      required: true,
    },
  },

  computed: {
    notOrderedGroups () {
      return uiSettingsStore.getters.notOrderedGroups
    },
    alreadyOrderedGroups () {
      return uiSettingsStore.getters.alreadyOrderedGroups
    },
    taskDeskCurrentGroupsOrder () {
      return uiSettingsStore.getters.taskDeskCurrentGroupsOrder
    },
    currentDeskGroups () {
      return uiStore.getters.currentDeskGroups
    },
    columnIndex () {
      return this.taskDeskCurrentGroupsOrder.indexOf(this.uid)
    },
    columnIndexInUnorderedGroups () {
      return this.notOrderedGroups.findIndex(group => group.key === this.uid)
    },
    isFirst () {
      return (
        (this.columnIndex === 0 && this.notOrderedGroups.length === 0) ||
        this.columnIndexInUnorderedGroups === 0
      )
    },
    isLast () {
      return this.columnIndex === this.taskDeskCurrentGroupsOrder.length - 1
    },
    editProjectOption () {
      if (!teamsStore.getters.currentTeam.me.canManageProjects) return null

      const section = sectionsStore.getters.section(this.uid, 'task')
      if (!section) return null

      const label = this.$t('modals.EditSection.edit')
      const action = () => {
        this.sendGoal('Изменить проект')

        uiStore.actions.showModal({
          instance: 'edit-section',
          payload: { uid: this.uid },
        })
      }

      return { label, action }
    },
    moveToStartOption () {
      if (this.isFirst) return null

      return {
        label: this.$t('dashboard.moveTo.start'),
        action: () => {
          this.sendGoal('В начало')
          this.moveGroup(true)
        },
      }
    },
    moveToEndOption () {
      if (this.isLast) return null

      return {
        label: this.$t('dashboard.moveTo.end'),
        action: () => {
          this.sendGoal('В конец')
          this.moveGroup(false)
        },
      }
    },
  },

  methods: {
    moveGroup (start) {
      let orderedGroups = this.alreadyOrderedGroups

      if (start || this.columnIndex === -1) {
        const newGroups = this.notOrderedGroups
        orderedGroups = [...newGroups, ...orderedGroups]
      }

      const oldIndex = orderedGroups.findIndex(g => g.key === this.uid)

      if (oldIndex === -1) return

      const groupToMove = orderedGroups[oldIndex]

      orderedGroups.splice(oldIndex, 1) // remove from old position
      start
        ? orderedGroups.unshift(groupToMove) // add to the start (first column)
        : orderedGroups.push(groupToMove) // add to the end (last column)

      uiSettingsStore.actions.setTaskDeskColumnOrder({
        orderedGroups,
        movedColumnKey: this.uid,
      })
    },
    sendGoal (msg) {
      const columnNames = {
        status: 'Статус',
        section: 'Проект',
        assignee: 'Исполнитель',
        owner: 'Постановщик',
        tag: 'Тег',
        importance: 'Приоритет',
      }

      window.goal('dashboardControls', {
        dashboardControls: `Колонка «${columnNames[this.columnKey] ?? this.columnKey}» — «${msg}»`,
      })
    },
  },
}
